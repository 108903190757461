<template>
  <n-carousel show-arrow keyboard style="max-width: 800px;">
    <img
      class="carousel-img"
      src="https://drive.google.com/uc?id=1yV6nZmFjqeLzOMkSTR-wmHjpaW_WHgP3"
    >
    <img
      class="carousel-img"
      src="https://drive.google.com/uc?id=1xwWWCC66B_mSNyAhL24Bws5EAhk72qIS"
    >
    <img
      class="carousel-img"
      src="https://drive.google.com/uc?id=1OpEvwr1rFFIN-G1SVtX_GqIATisX-vgJ"
    >
    <img
      class="carousel-img"
      src="https://drive.google.com/uc?id=1q3DkkSz9LVtCmyIdA1Ssoen0QrqNxlYX"
    >

    <template #arrow="{ prev, next }">
      <div class="custom-arrow">
        <button type="button" class="curtom-arrow--left" @click="prev">
          <n-icon><ArrowBack /></n-icon>
        </button>
        <button type="button" class="curtom-arrow--right" @click="next">
          <n-icon><ArrowForward /></n-icon>
        </button>
      </div>
    </template>
    <template #dots="{ total, currentIndex, to }">
      <ul class="custom-dots">
        <li
          v-for="index of total"
          :key="index"
          :class="{ ['is-active']: currentIndex === index - 1 }"
          @click="to(index - 1)"
        />
      </ul>
    </template>

  </n-carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { ArrowBack, ArrowForward } from '@vicons/ionicons5'

export default defineComponent({
  components: {
    ArrowBack,
    ArrowForward
  },
  setup () {
    return {
    }
  }
})
</script>

<style scoped>
.carousel-img {
  max-width: 100%;
  padding: 0px 0px 30px 0px;
  max-height: 450px;
  object-fit: cover;
}

.custom-arrow {
  display: flex;
  position: absolute;
  bottom: 15px;
  right: 10px;
}

.custom-arrow button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-right: 12px;
  color: #fff;
  background-color: var(--primary-color);
  border-width: 0;
  border-radius: 8px;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.custom-arrow button:hover {
  background-color: var(--success-color);
}

.custom-arrow button:active {
  transform: scale(0.95);
  transform-origin: center;
}

.custom-dots {
  display: flex;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.custom-dots li {
  display: inline-block;
  width: 12px;
  height: 4px;
  margin: 0 3px;
  border-radius: 4px;
  background-color: var(--success-color);
  transition: width 0.3s, background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.custom-dots li.is-active {
  width: 40px;
  background: var(--primary-color);
}
</style>
